let letHeader, letGlobalNavigations, resOutput, resHeadHeight, TopBranch, letSetScr, letVisualHeight, resVisualHeight, letEnclosingWrap, resEnclosingWrap, letPartnerbox;
letHeader = $('#header');
TopBranch = $('#indexTop');
letGlobalNavigations = $('#globalNavigations');
letVisualHeight = $('.mainVisualLayout');
letEnclosingWrap = $('.enclosingWrap');
letPartnerbox = $('#partnerbox');
/*ヘッダーの高さ*/
resHeadHeight = letHeader.outerHeight();
/*ビジュアルの高さ*/
resVisualHeight = letVisualHeight.outerHeight() - 150;
/*トップ判別*/
resOutput = letHeader.attr('class');
/*ヘッダーの色割り振り処理*/
export function headerScrFunc() {
  if (TopBranch.length) {
    letSetScr = $(window).scrollTop();
    resEnclosingWrap = parseInt(letSetScr) + parseInt(resHeadHeight);
    if (resVisualHeight < resEnclosingWrap) {
      letGlobalNavigations.find('ul li a').removeClass('topCol');
      letPartnerbox.find('a').removeClass('topCol');
      letHeader.addClass('addBgCor visible');
    } else {
      letGlobalNavigations.find('ul li a').addClass('topCol');
      letPartnerbox.find('a').addClass('topCol');
      letHeader.removeClass('addBgCor');
    }
  }
}
/*ヘッダー　トップページ以外の場合の処理*/
export function headerOutputFunc() {
  if (resOutput == 'nonTop') {
    $('header#header').fadeIn(500);
    letHeader.addClass('addBgCor visible');
  }
}
