//トップページ
import Swiper from 'swiper';
import anime from 'animejs/lib/anime.es.js';
import * as Vivus from 'vivus';

let vueFlagTop, letTop;
letTop = $('#indexTop').length;

export function idxFunc() {
  /*トップページ専用*/
  if (letTop) {
    vueFlagTop = true;
  }
  /*トップページだったら発動*/
  if (vueFlagTop) {
    let waveTop1, waveTop2, waveTop3;

    function topStroke1() {
      setTimeout(function () {
        $('#waveVueAnime1').css('opacity', '1');
        waveTop1 = new Vivus('waveVueAnimeA', {
          type: 'sync',
          duration: 400,
          forceRender: false,
          animTimingFunction: Vivus.EASE_OUT,
          start: 'autostart',
          reverseStack: true,
        });
      }, 500);
    }

    function topStroke2() {
      setTimeout(function () {
        $('#waveVueAnime2').css('opacity', '1');
        waveTop2 = new Vivus('waveVueAnimeB', {
          type: 'sync',
          duration: 200,
          forceRender: false,
          animTimingFunction: Vivus.EASE,
          start: 'autostart',
          reverseStack: true,
        });
      }, 1000);
    }

    function topStroke3() {
      setTimeout(function () {
        $('#waveVueAnime3').css('opacity', '1');
        waveTop3 = new Vivus('waveVueAnimeC', {
          type: 'sync',
          duration: 200,
          forceRender: false,
          animTimingFunction: Vivus.EASE_OUT,
          start: 'autostart',
          reverseStack: true,
        });
      }, 1500);
    }

    function waveControlFunc() {
      $('#loaderBg').delay(500).fadeOut(500);
      $('#loader').fadeOut(500);
      topStroke1();
      topStroke2();
      topStroke3();
    }
    waveControlFunc();

    /*見出し表示*/
    function TopTxtStagingFunc() {
      setTimeout(function () {
        $('#posTxt').fadeIn(1500, 'easeInSine');
      }, 2300);
      setTimeout(function () {
        $('#header,#prime').addClass('visible');
      }, 2600);
      //2600
      setTimeout(function () {
        anime({
          targets: '#jsPostSeminar',
          opacity: {
            value: [0, 1],
            duration: 1000,
            easing: 'easeInQuint',
          },
          direction: 'normal',
        });
      }, 2300);
    }

    //スワイプ実行処理
    let topSwiper, topOptions;
    topOptions = {
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false
      },
      speed: 3000,
    };

    function initTopSwiper() {
      topSwiper = new Swiper('#topSlide', topOptions);
    }
    anime({
      targets: '#VisualPos',
      opacity: {
        value: [0, 1],
        duration: 1500,
        easing: 'easeInQuint',
      },
      translateY: {
        value: ['-30%', '0'],
        duration: 1750,
        easing: 'easeOutExpo',
      },
      direction: 'normal',
      delay: 1100,
      begin: function () {
        TopTxtStagingFunc();
      },
      complete: function () {
        initTopSwiper();
      },
    });
  }
}

//* -------------------------------------------------------------------
//Alpha波のアニメーション設定
//------------------------------------------------------------------- */
/*Alpha波のアニメーション設定1*/
function alphaWaveSetting1() {
  setTimeout(function () {
    $('#waveVueAnimeAlphaA').css('opacity', '1');
    new Vivus('waveVueAnimeAlpha1', {
      type: 'sync',
      duration: 400,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 500);
}
/*Alpha波のアニメーション設定2*/
function alphaWaveSetting2() {
  setTimeout(function () {
    $('#waveVueAnimeAlphaB').css('opacity', '1');
    new Vivus('waveVueAnimeAlpha2', {
      type: 'sync',
      duration: 400,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 1000);
}

//* -------------------------------------------------------------------
//beta波のアニメーション設定
//------------------------------------------------------------------- */
/*beta波のアニメーション設定1*/
function betaWaveSetting1() {
  setTimeout(function () {
    $('#waveVueAnimeBetaA').css('opacity', '1');
    new Vivus('waveVueAnimeBeta1', {
      type: 'sync',
      duration: 400,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 0);
}
/*beta波のアニメーション設定2*/
function betaWaveSetting2() {
  setTimeout(function () {
    $('#waveVueAnimeBetaB').css('opacity', '1');
    new Vivus('waveVueAnimeBeta2', {
      type: 'sync',
      duration: 250,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 0);
}
/*beta波のアニメーション設定3*/
function betaWaveSetting3() {
  setTimeout(function () {
    $('#waveVueAnimeBetaC').css('opacity', '1');
    new Vivus('waveVueAnimeBeta3', {
      type: 'sync',
      duration: 150,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 800);
}

/*beta波のアニメーション設定4*/
function betaWaveSetting4() {
  setTimeout(function () {
    $('#waveVueAnimeBetaD').css('opacity', '1');
    new Vivus('waveVueAnimeBeta4', {
      type: 'sync',
      duration: 400,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 1000);
}

//* -------------------------------------------------------------------
//gamma波のアニメーション設定
//------------------------------------------------------------------- */
/*gamma波のアニメーション設定1*/
function gammaWaveSetting1() {
  setTimeout(function () {
    $('#waveVueAnimeGammaA').css('opacity', '1');
    new Vivus('waveVueAnimeGamma1', {
      type: 'sync',
      duration: 600,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 500);
}
/*gamma波のアニメーション設定2*/
function gammaWaveSetting2() {
  setTimeout(function () {
    $('#waveVueAnimeGammaB').css('opacity', '1');
    new Vivus('waveVueAnimeGamma2', {
      type: 'sync',
      duration: 220,
      forceRender: false,
      animTimingFunction: Vivus.EASE_OUT,
      start: 'autostart',
      reverseStack: true,
    });
  }, 500);
}

let targetAlpha, resTargetAlpha, resDistanceWaveAlpha, targetBeta, resTargetBeta, resDistanceWaveBeta, targetGamma, resTargetGamma, resDistanceWaveGamma;
targetAlpha = $('#alpha');
targetBeta = $('#beta');
targetGamma = $('#gamma');

let alphaFlag, betaFlag, gammaFlag;
alphaFlag = betaFlag = gammaFlag = false;
let scrollPos, topWH;
export function scrIndexWaveFunc() {
  if (letTop) {
    vueFlagTop = true;
  }
  if (vueFlagTop) {
    // スクロール量を取得
    scrollPos = $(window).scrollTop();
    //画面の高さを取得
    topWH = $(window).height();
    //発火対象
    resTargetAlpha = targetAlpha.offset().top - topWH;
    resTargetBeta = targetBeta.offset().top - topWH;
    resTargetGamma = targetGamma.offset().top - topWH;

    //data-distance('発火するまでの距離')
    resDistanceWaveAlpha = parseInt(targetAlpha.data('distance'));
    resDistanceWaveBeta = parseInt(targetBeta.data('distance'));
    resDistanceWaveGamma = parseInt(targetBeta.data('distance'));
    //2番目の波アニメーション
    if (resTargetAlpha + resDistanceWaveAlpha < scrollPos) {
      if (!alphaFlag) {
        alphaFlag = true;
        alphaWaveSetting1();
        alphaWaveSetting2();
      }
    }

    //3番目の波アニメーション
    if (resTargetBeta + resDistanceWaveBeta < scrollPos) {
      if (!betaFlag) {
        betaFlag = true;
        betaWaveSetting1();
        betaWaveSetting2();
        betaWaveSetting3();
        betaWaveSetting4();
      }
    }

    //3番目の波アニメーション
    if (resTargetGamma + resDistanceWaveGamma < scrollPos) {
      if (!gammaFlag) {
        gammaFlag = true;
        gammaWaveSetting1();
        gammaWaveSetting2();
      }
    }
  }
}

let resTargetFundA, resTargetcorPo;
let fundAFlag, corPoFlag;
fundAFlag = corPoFlag = false;
export function scrIndexListFunc() {
  if (letTop) {
    vueFlagTop = true;
  }
  if (vueFlagTop) {
    // スクロール量を取得
    scrollPos = $(window).scrollTop();
    //画面の高さを取得
    topWH = $(window).height();
    //対象の距離
    resTargetFundA = $('.fundA').offset().top - topWH;
    resTargetcorPo = $('.corPo').offset().top - topWH;
    if (resTargetFundA + 150 < scrollPos) {
      if (!fundAFlag) {
        fundAFlag = true;
        anime({
          targets: '.fundA li',
          opacity: {
            value: [0, 1],
            duration: 800,
            easing: 'easeInExpo',
          },
          translateY: {
            value: ['350', '0'],
            duration: 1000,
            easing: 'easeOutQuart',
          },
          direction: 'normal',
          delay: function (el, i, l) {
            return i * 100;
          }
        });
      }
    }
    if (resTargetcorPo + 50 < scrollPos) {
      if (!corPoFlag) {
        corPoFlag = true;
        anime({
          targets: '.corPo li',
          duration: 3000,
          opacity: {
            value: [0, 1],
            duration: 800,
            easing: 'easeInExpo',
          },
          translateY: {
            value: ['350', '0'],
            duration: 1000,
            easing: 'easeOutQuart',
          },
          direction: 'normal',
          delay: function (el, i, l) {
            return i * 100;
          }
        });
      }
    }
  }
}