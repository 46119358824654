import {
	resizecheck,
  resizeFunc,
} from './_resizecheck';
export function footerFunc() {
	let footerAccordion;
	footerAccordion = $('.accordionSp');
	
	footerAccordion.on('click', function(){
		resizeFunc();
		if(resizecheck == 2){
			$(this).toggleClass('open');
		}
	});
}
