// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#topmove');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight, position;
  $('a[href^="#"].ankerLink').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click', function () {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top - headerHeight;
        $('body,html').animate({
          scrollTop: position
        }, 1000, 'easeInOutQuart');
        return false;
      });
    }
  });
}

export function outSideFunc() {
var headerHeight = $('#header').outerHeight();   //fixedのヘッダーの高さを取得
var urlHash = location.hash;    //URLハッシュを取得
var animeSpeed = 0;   //スクロールのアニメーションスピード
console.log(urlHash);
if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
    }, 100);
};
}
