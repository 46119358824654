const contactPage = $('#contactForm').length;
const setsumeikai = $('#setsumeikai').length;
const recruitApply = $('#recruitApply').length;
const materialsPage = $('#materials').length;
const othersPage = $('#others').length;
const contactCareersPage = $('#contactCareers').length;
const contactProductsPage = $('#contactProducts').length;
const contactInferiorPage = $('#contactInferior').length;

export function recruitContactFunc() {
  if (contactPage) {

    const typeSelect = $("#suggestedWorking");
    let selectIndex = '';

    const formField = $('.linkText');

    // //希望勤務地選択状態で表示変更
    // const startSelected = typeSelect.val();
    // if(!startSelected || startSelected == '在宅'){
    //   $('#selectJobList').hide().addClass("notSelect");
    // }else if(startSelected == '東京' || startSelected == '神戸'){
    //   $('#selectJobList').show().addClass("notSelect");
    //   $('.tokyoOffice').show();
    // }else if (startSelected == '福岡') {
    //   $('#selectJobList').show().addClass("notSelect");
    //   $(".tokyoOffice").hide();
    //   $('.singaporeOffice').hide();
    //   $('.fukuokaOffice').show();
    // } else if (startSelected == 'シンガポール') {
    //   $('#selectJobList').show().addClass("notSelect");
    //   $(".tokyoOffice").hide();
    //   $('.fukuokaOffice').hide();
    //   $('.singaporeOffice').show();
    // }
        // function radioReset(){
        //   let queryStr = 'input[name="jobSelect"]'
        //   let radioButtons = document.querySelectorAll(queryStr);
        //   radioButtons.forEach(radioButton => {
        //     radioButton.checked = false;
        //   });
        // }
        // // 職種表示
        // const selectControl = function () {

        //   typeSelect.on('change', function(){
        //     const selected = typeSelect.val();
        //     if (selected != '') {
        //       if (selected == '東京' || selected == '神戸') {
        //         radioReset();
        //         $('#selectJobList').show().addClass("notSelect");
        //         $('.tokyoOffice').show();
        //       } else if (selected == '福岡') {
        //         radioReset();
        //         $('#selectJobList').show().addClass("notSelect");
        //         $(".tokyoOffice").hide();
        //         $('.singaporeOffice').hide();
        //         $('.fukuokaOffice').show();
        //       } else if (selected == 'シンガポール') {
        //         radioReset();
        //         $('#selectJobList').show().addClass("notSelect");
        //         $(".tokyoOffice").hide();
        //         $('.fukuokaOffice').hide();
        //         $('.singaporeOffice').show();
        //       } else if (selected == '在宅') {
        //         radioReset();
        //         $('#selectJobList').hide().removeClass("notSelect");
        //         $('#selectJobList .radioArea').removeClass("error").next('.errorTxt').text('');;
        //       }
        //     }
        //   });
        // }


    // 必須項目名
    const companyName = $("[name='companyName']");
    const mediaName = $("[name='mediaName']");
    const contactName = $("[name='contactName']");
    const myName = $("[name='myName']");
    const myNameKana = $("[name='myNameKana']");
    const birthdate = $("[name='birthdate']");
    const email = $("[name='email']");
    const emailCheck = $("[name='emailCheck']");
    const telNumber = $("[name='telNumber']");
    const subject = $("[name='subject']");
    const suggestedWorking = $("[name='suggestedWorking']");
    const product = $("[name='product']");
    const productsBrand = $("[name='productsBrand']");
    const productsName = $("[name='productsName']");
    const content = $("[name='content']");
    const businessCompanyName = $("[name='businessCompanyName']");
    const yearMonth = $("[name='yearMonth']");
    const capital = $("[name='capital']");
    const employeeNum = $("[name='employeeNum']");
    const tradingCompany = $("[name='tradingCompany']");
    const annualSales = $("[name='annualSales']");
    const businessContactName = $("[name='businessContactName']");
    const companyAddress = $("[name='companyAddress']");
    const companyTelNumber = $("[name='companyTelNumber']");
    const companyEmail = $("[name='companyEmail']");
    const companyEmailCheck = $("[name='companyEmailCheck']");
    // const jobSelect = $("[name='jobSelect']");
    const tradingPlans = $("[name='tradingPlans']");
    const tradingCountry = $("[name='tradingCountry']");
    const salesForms = $("[name='salesForms']");
    const monthlySales = $("[name='monthlySales']");
    const otherRequest = $("[name='otherRequest']");
    const agreeCheck = $("[name='agreeCheck']");
    const contactDateY = $("[name='contactDateY']");
    const contactDateM = $("[name='contactDateM']");
    const contactDateD = $("[name='contactDateD']");
    const salesStyle = $("[name='salesStyle']");
    const prRequest = $("[name='prRequest']");

    // 必須項目定義
    const businessRequired = [myName, myNameKana , subject, birthdate , companyAddress, companyTelNumber, companyEmail, companyEmailCheck, tradingPlans, salesForms, otherRequest , agreeCheck , contactDateY ,contactDateM , contactDateD ,salesStyle];
    const recruitApplyRequired = [myName, myNameKana , subject , suggestedWorking, birthdate , companyAddress, companyTelNumber, companyEmail, companyEmailCheck, tradingPlans, salesForms, otherRequest , agreeCheck ,salesStyle];

    // 必須項目設定
    let requiredField = [];
    const requiredFieldSet = function () {
      if(setsumeikai){
        requiredField = businessRequired;
      }else if(recruitApply){
        requiredField = recruitApplyRequired;
      }
      for (let i = 0; i < requiredField.length; i++) {
        let requiredFieldType = requiredField[i].attr('type');
        if (requiredFieldType == 'radio' || requiredFieldType == 'checkbox') {
          requiredField[i].change(function () {
            fieldCheck(requiredField[i]);
          });
        } else {
          requiredField[i].blur(function () {
            fieldCheck(requiredField[i]);
          });
        }
      }
    }

    // リロード時実行
    requiredFieldSet();
    // selectControl();

    // セレクト選択時実行
    typeSelect.change(function () {
      formField.removeClass('isShow');
      selectIndex = typeSelect.val();
      requiredFieldSet();
      // selectControl();
    });



    const agreeCheckTxt = '「個人情報保護方針に同意する」にチェックをしてください。';
    const emailRequiredTxt = '正しいメールアドレスを入力してください。';
    const emailDuplication = '上記フォームで入力したメールアドレスと異なります。';
    const telRequiredTxt = '10桁〜12桁の数字を入力してください。';
    const kanaRequireTxt = '全角カタカナで入力してください。'

    let errorTxt = '';
    const fieldCheck = function (checkField, all = false) {
      let checkFieldValue = checkField.val();
      let checkFieldType = checkField.attr('type');
      let checkFieldTag = checkField[0].tagName;
      let checkFieldName = checkField.attr('name');
      let checkFieldDataName = checkField.attr('data-name');
      let checkFieldLabel = $('[for= ' + checkFieldName + ']');
      let checkFieldLabelTxt = checkFieldLabel.text().replace("※", "");
      if (checkFieldType == 'radio') {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (!checkField.is(':checked')) {
          checkField.parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          checkField.parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField == agreeCheck) {
        if (!checkField.is(':checked')) {
          checkField.parents('.checkboxArea').addClass('error').next('.errorTxt').text(agreeCheckTxt);
        } else {
          checkField.parents('.checkboxArea').removeClass('error').next('.errorTxt').text('');
        }
      } else if (checkField.hasClass('select')) {
        errorTxt = checkFieldLabelTxt + 'は選択必須です。';
        if (checkFieldValue == '') {
          checkField.addClass('error').parent('.selectBtn').next('.errorTxt').text(errorTxt);
        } else {
          checkField.removeClass('error').parent('.selectBtn').next('.errorTxt').text('');
        }
      } else {
        errorTxt = checkFieldLabelTxt + 'は入力必須です。';
        if (checkFieldName == 'content' && selectIndex != 'inferior') {
          errorTxt = 'お問い合わせ内容は入力必須です。';
        }
        if (checkFieldValue == '') {
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else if (checkFieldType == 'email' && !checkFieldValue.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          checkField.addClass('error').next('.errorTxt').text(emailRequiredTxt);
        } else if (checkField == emailCheck && checkFieldValue != email.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        } else if (checkField == companyEmailCheck && checkFieldValue != companyEmail.val()) {
          checkField.addClass('error').next('.errorTxt').text(emailDuplication);
        } else if (checkFieldType == 'tel' && !checkFieldValue.match('^[0-9]{10,12}$')) {
          checkField.addClass('error').next('.errorTxt').text(telRequiredTxt);
        } else if (checkFieldTag == 'TEXTAREA' && checkFieldValue.length >= 501) {
          errorTxt = '500文字以内で入力してください';
          checkField.addClass('error').next('.errorTxt').text(errorTxt);
        } else if (checkFieldName == 'myNameKana' && !checkFieldValue.match(/^[ァ-ヶー　]+$/)){
          checkField.addClass('error').next('.errorTxt').text(kanaRequireTxt);
        }else {
          checkField.removeClass('error').next('.errorTxt').text('');
        }
      }
    };

    otherRequest.blur(function () {
      if (otherRequest.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        otherRequest.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        otherRequest.removeClass('error').next('.errorTxt').text('');
      }
    });
    prRequest.blur(function () {
      if (prRequest.val().length >= 501) {
        errorTxt = '500文字以内で入力してください';
        prRequest.addClass('error').next('.errorTxt').text(errorTxt);
      } else {
        prRequest.removeClass('error').next('.errorTxt').text('');
      }
    });

    // $('.tradingProducts').on('change', function(){
    //   var r = $('.tradingProducts:checked').length > 0;
    //   errorTxt = 'やってみたい仕事の内容は選択必須です。';

    //   if(r){
    //     $(this).parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
    //   }else{
    //     $(this).parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
    //   }
    // });

    // function is_checked(){
    //   var cnt = 0;
    //   cnt += $('.tradingProducts:checked').length;
    //   errorTxt = 'やってみたい仕事の内容は選択必須です。';

    //   if(cnt > 0){
    //     $('.tradingProducts').parents('.checkboxArea02').removeClass('error').next('.errorTxt').text('');
    //   }else{
    //     $('.tradingProducts').parents('.checkboxArea02').addClass('error').next('.errorTxt').text(errorTxt);
    //   }
      
    //   return cnt;
    // }

    $('.jobSelect').on('change', function(){
      var r = $('.jobSelect:checked').length > 0;
      errorTxt = '職種は選択必須です。';

      if(r){
        $(this).parents('.radioArea').removeClass('error').next('.errorTxt').text('');
      }else{
        $(this).parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
      }
    });

    function is_checked2(){
        const selected = typeSelect.val();
        if (!selected) {
          var cnt = 0;
          cnt += $('.jobSelect:checked').length;
          errorTxt = '職種は選択必須です。';
          if(cnt > 0){
            $("[name='jobSelect']").parents('.radioArea').removeClass('error').next('.errorTxt').text('');
          }else{
            $("[name='jobSelect']").parents('.radioArea').addClass('error').next('.errorTxt').text(errorTxt);
          }
          return cnt;
        }else{
          $("[name='jobSelect']").parents('.radioArea').removeClass('error').next('.errorTxt').text('');
        }
    }

    //公認会計士資格取得の有無、受験中選択時
    function is_checked3(){
      const radioField1 = $("[name='salesForms']:checked")
      const radioField1Val = radioField1.val();
      let target1 = $(".radioField3")
      if (radioField1Val == '2.受験中') {
        if (target1.val().length === 0) {
          //エラーの時
          errorTxt = '入力必須です。';
          target1.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          target1.removeClass('error').next('.errorTxt').text('');
        }
      }else{
        target1.removeClass('error').next('.errorTxt').text('');
      }
  }

  //税理士資格取得の有無
    function is_checked4(){
      const radioField2 = $("[name='tradingPlans']:checked")
      const radioField2Val = radioField2.val();
      let target2 = $("[name='tradingPlansOther']");
      let target3 = $("[name='entranceExam']")
      if (radioField2Val == '1.資格取得済み') {
        if (target2.val().length === 0) {
          errorTxt = '合格科目は入力必須です。';
          target2.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          target2.removeClass('error').next('.errorTxt').text('');
        }
        target3.removeClass('error').next('.errorTxt').text('');
      }else if(radioField2Val == '2.受験中'){
        if (target3.val().length === 0) {
          errorTxt = '受験中科目は入力必須です。';
          target3.addClass('error').next('.errorTxt').text(errorTxt);
        } else {
          target3.removeClass('error').next('.errorTxt').text('');
        }
        target2.removeClass('error').next('.errorTxt').text('');
      }else{
        target2.removeClass('error').next('.errorTxt').text('');
        target3.removeClass('error').next('.errorTxt').text('');
      }
  }



    let sendBtn = $('#sendBtn');
    // 各項目をサブミット処理でチェック（個人、法人フォーム）
    $('form').submit(function (e) {
      // 初期設定
      let set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      // 各項目をチェック（個人フォーム用）
      for (let i = 0; i < requiredField.length; i++) {
        fieldCheck(requiredField[i]);
      }

      // is_checked();
      is_checked2();
      is_checked3();
      is_checked4();

      // エラー箇所があればその場所までスクロール
      let offsetTxt, headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $('.error').offset().top;
        headerHeight = $('.header').outerHeight();
        $('html,body').animate({
          scrollTop: offsetTxt - headerHeight
        }, 500);
        return false;
      }

      // エラーがなければ二重送信禁止
      sendBtn.prop('disabled', 'true');
      setTimeout(function () {
        sendBtn.prop('disabled', '');
      }, 1000);
      return true;
    });

    // ラジオボタンチェック時入力欄有効可
    const radioBtn = document.querySelectorAll('.radioBtn');
    const radioField = document.querySelectorAll('.radioField');

    for (let i = 0; i < radioBtn.length; i++) {
      if (radioBtn[i].checked) {
        radioField[i].disabled = false;
          if(i == 0 ){
            let target1 = $(".radioField1")
            target1.blur(function () {
              if (target1.val().length == 0) {
                //エラーの時
                errorTxt = '合格科目は入力必須です。';
                target1.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target1.removeClass('error').next('.errorTxt').text('');
              }
            });
          }else if(i == 1 ){
            let target2 = $(".radioField2")
            target2.blur(function () {
              if (target2.val().length == 0) {
                //エラーの時
                errorTxt = '受験中科目は入力必須です。';
                target2.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target2.removeClass('error').next('.errorTxt').text('');
              }
            });
          }else if(i == 2 ){
            let target3 = $(".radioField3")
            target3.blur(function () {
              if (target3.val().length == 0) {
                //エラーの時
                errorTxt = '入力必須です。';
                target3.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target3.removeClass('error').next('.errorTxt').text('');
              }
            });
          }

      } else {
        radioField[i].disabled = true;

          if(i == 0 ){
            let target1 = $(".radioField1")
            target1.removeClass('error').next('.errorTxt').text('');
          }else if(i == 1 ){
            let target2 = $(".radioField2")
            target2.removeClass('error').next('.errorTxt').text('');
          }else if(i == 2 ){
            let target3 = $(".radioField3")
            target3.removeClass('error').next('.errorTxt').text('');
          }
      }
    }
    $("input[type='radio']").on('click', function () {
      for (let i = 0; i < radioBtn.length; i++) {
        if (radioBtn[i].checked) {
          radioField[i].disabled = false;
          if(i == 0 ){
            let target1 = $(".radioField1")
            target1.blur(function () {
              if (target1.val().length == 0) {
                //エラーの時
                errorTxt = '合格科目は入力必須です。';
                target1.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target1.removeClass('error').next('.errorTxt').text('');
              }
            });
          }else if(i == 1 ){
            let target2 = $(".radioField2")
            target2.blur(function () {
              if (target2.val().length == 0) {
                //エラーの時
                errorTxt = '受験中科目は入力必須です。';
                target2.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target2.removeClass('error').next('.errorTxt').text('');
              }
            });
          }else if(i == 2 ){
            let target3 = $(".radioField3")
            target3.blur(function () {
              if (target3.val().length == 0) {
                //エラーの時
                errorTxt = '入力必須です。';
                target3.addClass('error').next('.errorTxt').text(errorTxt);
              } else {
                target3.removeClass('error').next('.errorTxt').text('');
              }
            });
          }
        } else {
          radioField[i].disabled = true;
          radioField[i].value = '';
          if(i == 0 ){
            let target1 = $(".radioField1")
            target1.removeClass('error').next('.errorTxt').text('');
          }else if(i == 1 ){
            let target2 = $(".radioField2")
            target2.removeClass('error').next('.errorTxt').text('');
          }else if(i == 2 ){
            let target3 = $(".radioField3")
            target3.removeClass('error').next('.errorTxt').text('');
          }
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn = document.querySelectorAll('.checkBtn');
    const checkField = document.querySelectorAll('.checkField');
    for (let i = 0; i < checkBtn.length; i++) {
      if (checkBtn[i].checked) {
        checkField[i].disabled = true;
        checkField[i].value = '';
      } else {
        checkField[i].disabled = false;
      }
    }
    $(".checkboxArea input[type='checkbox']").on('click', function () {
      for (let i = 0; i < checkBtn.length; i++) {
        if (checkBtn[i].checked) {
          checkField[i].disabled = true;
          checkField[i].value = '';
        } else {
          checkField[i].disabled = false;
        }
      }
    });

    // チェックボックスチェック時入力欄有効可
    const checkBtn02 = document.querySelectorAll('.checkBtn02');
    const checkField02 = document.querySelectorAll('.checkField02');
    for (let i = 0; i < checkBtn02.length; i++) {
      if (checkBtn02[i].checked) {
        checkField02[i].disabled = false;
      } else {
        checkField02[i].disabled = true;
        checkField02[i].value = '';
      }
    }
    $(".checkboxArea02 .checkBtn02").on('click', function () {
      for (let i = 0; i < checkBtn02.length; i++) {
        if (checkBtn02[i].checked) {
          checkField02[i].disabled = false;
        } else {
          checkField02[i].disabled = true;
          checkField02[i].value = '';
        }
      }
    });
  }
}
