import anime from 'animejs/lib/anime.es.js';
let resPosTarget, selectors, resStatusNow;

let ListFFlag, ListCFlag;
ListFFlag = ListCFlag = false;

selectors = $('.scrTarget');
export function scrPositionFunc() {
  // スクロール量を取得
  const scrollTop = $(window).scrollTop();
  //画面の高さを取得
  const WH = $(window).height();

  //  var playPauseAnim = anime({
  //    targets: '.fundA li',
  //    autoplay: false,
  //    duration: 1000,
  //    opacity: {
  //      value: [0, 1],
  //      duration: 800,
  //      easing: 'easeInOutExpo',
  //    },
  //    translateY: {
  //      value: ['-250', '0'],
  //      duration: 800,
  //      easing: 'easeInOutExpo',
  //    },
  //    easing: 'easeInOutExpo',
  //    direction: 'normal',
  //    delay: function (el, i, l) {
  //      return i * 500;
  //    }
  //  });

  function animationFunc(_this, resType, status) {
    if (resType == 'amplification') {
      _this.addClass("ampA");
    } else if (resType == 'more') {
      _this.addClass("ampA");
//      if (status == 'unexecuted' && !ListFFlag) {
//        ListFFlag = true;
//        anime({
//          targets: '.fundA li',
//          translateY: {
//            value: ['200px', '0'],
//            duration: 800,
//            easing: 'easeInOutExpo',
//          },
//          duration: 3000,
//          opacity: {
//            value: [0, 1],
//            duration: 800,
//            easing: 'easeInOutExpo',
//          },
//          delay: function (el, i, l) {
//            return i * 500;
//          }
//        });
//      }
      
//      if (status == 'unexecuted' && !ListCFlag) {
//        _this.addClass("ampB");
//        ListCFlag = true;
//        anime({
//          targets: '.corPo li',
//          translateY: {
//            value: ['200px', '0'],
//            duration: 800,
//            easing: 'easeInOutExpo',
//          },
//          duration: 3000,
//          opacity: {
//            value: [0, 1],
//            duration: 800,
//            easing: 'easeInOutExpo',
//          },
//          delay: function (el, i, l) {
//            return i * 500;
//          }
//        });
//      }
    }
  }


  //画面内に入ったら指定の関数を実行
  //data-typeanime('アニメーションの種類')
  //data-distance('発火するまでの距離')
  let resAnimeType, resDistance, resStatus;
  selectors.each(function () {
    resPosTarget = $(this).offset().top - WH;
    resAnimeType = $(this).data('typeanime');
    resDistance = parseInt($(this).data('distance'));
    resStatus = $(this).data('status');
    if (resPosTarget + resDistance < scrollTop) {
      animationFunc($(this), resAnimeType, resStatus);
    }
  });
}