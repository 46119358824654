// jQueryプラグイン
import 'jquery.easing';

var n = '';
var introBidderRequired = 'ご紹介者様の有無をご選択ください。';
var nameRequired = 'ご紹介者様の氏名をご入力ください。';
var myNameRequired = '氏名をご入力ください。';
var telephoneRequired = '電話番号を入力してください';
var emailRequired = 'メールアドレスをご入力ください。';
var contactCheckRequired = 'お問い合わせ項目をご選択ください。';
var messageRequired = 'お問い合わせ内容をご入力ください。';
var companyNameRequired = '会社名をご入力ください。';
var erpNum = '半角数字のみ入力してください。';
var erpZip = '半角数字7桁で入力して下さい。';
var erpTel = '半角数字10桁以上で入力して下さい。';
var erpFax = '半角数字10、11桁で入力してください。';
var erpSite = '入力されたURLは無効です。http:// または https:// で始まるURLを入力してください。';
var switchFlag = '';

export function contactFunc() {
  let contactPage = $('#formBox').length;
  if (contactPage) {
    /* ----------------------------------------------------------------------------------
    ロード時
    ---------------------------------------------------------------------------------- */
    if ($('input[name=introBidder]:eq(1)').prop('checked')) {
      // switchFlagの値をtrueに
      switchFlag = true;
    } else {
      // switchFlagの値をfalseに
      switchFlag = false;
    }
    if($('input[name=customers]:eq(0)').prop('checked')){
      $('.dlIndividual').show();
      $('.dlCorp').hide();
    }else{
      $('.dlIndividual').hide();
      $('.dlCorp').show();
    }

    /* ----------------------------------------------------------------------------------
    お客様区分ラジオボタン切り替え
    ---------------------------------------------------------------------------------- */
    $('input[name=customers]').change(function () {
      // 表示・非表示切り替え
      if ($('input[name=customers]:eq(0)').prop('checked')) {
        $('.dlIndividual').show();
        $('.dlCorp').hide();
        //$('#personTxt').show();
      } else if ($('input[name=customers]:eq(1)').prop('checked')) {
        $('.dlIndividual').hide();
        $('.dlCorp').show();
        //$('#personTxt').hide();
      }

      // 要素を表示
      $('#formBox').addClass('show');

      // 中身の入力があった場合は初期化
      $('form').find('textarea, :text, select').val('');
      $('form').find('input').each(function () {
        let type = $(this).attr('type');
        if (type != 'radio' && type != 'checkbox') {
          let name = $(this).attr('name');
          if (name != 'act' && name != 'ticket') {
            $(this).val('');
          }
        }
      });
      $('form').find('input[name=contactCheck]').prop('checked', false);
      $('form').find('input[name=contactCheckCorp]').prop('checked', false);
      $('form').find('input[name=introBidder]').prop('checked', false);
      $('form').find('input[name=itemCheck]').prop('checked', false);
      $('form').find('input[name=agreeCheck]').prop('checked', false);
      $('form').find('input[name=introBidderCorp]').prop('checked', false);
      $('.submit').find('button').prop('disabled', 'true'); // disabled付与
      $('.requireIntroName').show();

      // エラー系のスタイルがあった場合も初期化
      $('.errorTxt').text('');
      $('form').find('textarea, :text, select, input, div').removeClass('error');

      // ご紹介者様氏名のバリデートチェックを実行
      introNameFunc();

      // switchFlagの値をfalseに
      switchFlag = false;
    });


    /* ----------------------------------------------------------------------------------
    各項目をリアルタイムでチェック（個人フォーム）
    ---------------------------------------------------------------------------------- */
    // ご紹介者様の有無
    $("[name='introBidder']").on('change', function () {
      if ($(this).prop('checked')) {
        $('.introBidderBox').removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }

      // ご紹介者様氏名のバリデートの是非フラグチェック
      if ($('input[name=introBidder]:eq(1)').prop('checked')) {
        switchFlag = true;
        $('.requireIntroName').hide();
        $("input[name='introName']").off('blur'); //ここで毎回呼ばれる関数を止める
        $("input[name='introName']").removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      } else if ($('input[name=introBidder]:eq(0)').prop('checked')) {
        switchFlag = false;
        $('.requireIntroName').show();
        introNameFunc();
      }
    });

    // ご紹介者様氏名
    function introNameFunc() {
      $("input[name='introName']").on('blur', function () {
        if ($(this).val() == '') {
          if ($(this).next('.errorTxt').text() === '') {
            $(this).addClass('error').next('.errorTxt').text(nameRequired);
          }
        } else {
          $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
        }
      });
    }
    if (switchFlag === false || switchFlag === '') {
      introNameFunc();
    }

    // 氏名
    $("input[name='myName']").blur(function () {
      if ($(this).val() == '') {
        if ($(this).next('.errorTxt').text() === '') {
          $(this).addClass('error').next('.errorTxt').text(myNameRequired);
        }
      } else {
        $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // 会社名（法人用）
    $("input[name='companyName']").blur(function () {
      if ($(this).val() == '') {
        if ($(this).next('.errorTxt').text() === '') {
          $(this).addClass('error').next('.errorTxt').text(companyNameRequired);
        }
      } else {
        $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // ホームページURL（法人用）
    $("input[name='companySite']").blur(function () {
      if (!$(this).val() == '') {
        if (!$("input[name='companySite']").val().match(/https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g)) {
          $(this).addClass('error').next('.errorTxt').text(erpSite);
          return false;
        } else {
          $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
        }
      }
    });

    // 電話番号
    $("input[name='telName']").blur(function () {
      if (!$(this).val() == '') {
        // 半角数字でされているかをチェック
        if (!$("input[name='telName']").val().match(/^[0-9]+$/)) {
          $(this).addClass('error').next('.errorTxt').text(erpNum);
          return false;
        } else {
          // 番号の文字列チェック(10文字以上で許可)
          if ($("input[name='telName']").val().length <= 9) {
            $(this).addClass('error').next('.errorTxt').text(erpTel);
            return false;
          }
        }
        $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      } else {
        $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // メールアドレス
    $("[name='email']").blur(function () {
      if ($(this).val() == '') {
        if ($(this).next('.errorTxt').text() === '') {
          $(this).addClass('error').next('.errorTxt').text(emailRequired);
        }
      } else {
        // メールアドレスの形式であるかをチェック
        if (!$(this).val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
          $(this).addClass('error').next('.errorTxt').text(emailRequired);
        } else {
          $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
        }
      }
    });

    // お問い合わせ項目
    $("[name='contactCheck']").change(function () {
      if ($(this).prop('checked')) {
        $('.contactCheckBox').removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // お問い合わせ項目（法人用）
    $("[name='contactCheckCorp']").change(function () {
      if ($(this).prop('checked')) {
        $('.contactCheckBoxCorp').removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // お問い合わせ内容
    $("[name='content']").blur(function () {
      if ($(this).val() == '') {
        if ($(this).next('.errorTxt').text() === '') {
          $(this).addClass('error').next('.errorTxt').text(messageRequired);
        }
      } else {
        $(this).removeClass('error').next('.errorTxt').text(n); // 何もなければエラー記述を無しにする
      }
    });

    // 個人情報保護方針について
    $("[name='agreeCheck']").change(function () {
      if ($(this).prop('checked')) {
        $('.submit').find('button').prop('disabled', ''); // disabled解除
      } else {
        $('.submit').find('button').prop('disabled', 'true'); // disabled付与
      }
    });


    /* ----------------------------------------------------------------------------------
    各項目をサブミット処理でチェック（個人、法人フォーム）
    ---------------------------------------------------------------------------------- */
    $('form').submit(function (e) {
      // 初期設定
      var set = 0;
      if (set == 0) {
        set = 1;
      } else {
        return false;
      }

      if ($('.dlCorp').css('display') === 'none') {
        /* ----------------------------------------------------------------------------------
        各項目をチェック（個人フォーム用）
        ---------------------------------------------------------------------------------- */
        // ご紹介者様の有無
        if (!$('[name=introBidder]').is(':checked')) {
          $('.introBidderBox').addClass('error').next('.errorTxt').text(introBidderRequired);
          e.preventDefault();
        }

        // ご紹介者様氏名
        if (switchFlag === false || switchFlag === '') {
          if ($("input[name='introName']").val() == '') {
            $("input[name='introName']").addClass('error').next('.errorTxt').text(nameRequired);
            e.preventDefault();
          }
        }

        // 氏名
        if ($("input[name='myName']").val() == '') {
          $("input[name='myName']").addClass('error').next('.errorTxt').text(myNameRequired);
          e.preventDefault();
        }

        // 電話番号
        /*if($("input[name='telName']").val() == '') {
          $("input[name='telName']").addClass('error').next('.errorTxt').text(telephoneRequired);
          e.preventDefault();
        } else {
          // 半角数字でされているかをチェック
          if (!$("input[name='telName']").val().match(/^[0-9]+$/)) {
            $(this).addClass('error').next('.errorTxt').text(erpNum);
            e.preventDefault();
          } else {
            // 番号の文字列チェック(10文字以上で許可)
            if ($("input[name='telName']").val().length <= 9) {
              $(this).addClass('error').next('.errorTxt').text(erpTel);
              e.preventDefault();
            }
          }
        }*/

        // メールアドレス
        if ($("input[name='email']").val() == '') {
          $("input[name='email']").addClass('error').next('.errorTxt').html(emailRequired);
          e.preventDefault();
        } else {
          // メールアドレスの形式であるかをチェック
          if (!$("input[name='email']").val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
            $(this).addClass('error').next('.errorTxt').text();
            e.preventDefault();
          }
        }

        // お問い合わせ項目
        if (!$('[name=contactCheck]').is(':checked')) {
          $('.contactCheckBox').addClass('error').next('.errorTxt').text(contactCheckRequired);
          e.preventDefault();
        }

        // お問い合わせ内容
        if ($("[name='content']").val() == '') {
          $("[name='content']").addClass('error').next('.errorTxt').text(messageRequired);
          e.preventDefault();
        }
      } else {
        /* ----------------------------------------------------------------------------------
        各項目をチェック（法人フォーム用）
        ---------------------------------------------------------------------------------- */
        // ご紹介者様の有無
        if (!$('[name=introBidder]').is(':checked')) {
          $('.introBidderBox').addClass('error').next('.errorTxt').text(introBidderRequired);
          e.preventDefault();
        }

        // ご紹介者様氏名
        if (switchFlag === false || switchFlag === '') {
          if ($("input[name='introName']").val() == '') {
            $("input[name='introName']").addClass('error').next('.errorTxt').text(nameRequired);
            e.preventDefault();
          }
        }

        // 氏名
        if ($("input[name='myName']").val() == '') {
          $("input[name='myName']").addClass('error').next('.errorTxt').text(myNameRequired);
          e.preventDefault();
        }

        // 会社名
        if ($("input[name='companyName']").val() == '') {
          $("input[name='companyName']").addClass('error').next('.errorTxt').text(companyNameRequired);
          e.preventDefault();
        }

        // ホームページURL（法人用）
        if (!$("input[name='companySite']").val() == '') {
          if (!$("input[name='companySite']").val().match(/^https?:\/\//)) {
            $(this).addClass('error').next('.errorTxt').text(erpSite);
            e.preventDefault();
          }
        }

        // メールアドレス
        if ($("input[name='email']").val() == '') {
          $("input[name='email']").addClass('error').next('.errorTxt').html(emailRequired);
          e.preventDefault();
        } else {
          // メールアドレスの形式であるかをチェック
          if (!$("input[name='email']").val().match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
            $(this).addClass('error').next('.errorTxt').text();
            e.preventDefault();
          }
        }

        // お問い合わせ項目
        if (!$('[name=contactCheckCorp]').is(':checked')) {
          $('.contactCheckBoxCorp').addClass('error').next('.errorTxt').text(contactCheckRequired);
          e.preventDefault();
        }

        // お問い合わせ内容
        if ($("[name='content']").val() == '') {
          $("[name='content']").addClass('error').next('.errorTxt').text(messageRequired);
          e.preventDefault();
        }
      }


      /* ----------------------------------------------------------------------------------
      エラー箇所があればその場所までスクロール
      ---------------------------------------------------------------------------------- */
      var offsetTxt;
      var headerHeight;
      if ($('.errorTxt').text() != '') {
        offsetTxt = $(this).offset().top;
        headerHeight = $('#header').outerHeight();
        let pos = offsetTxt;
        console.log(pos);
        $('body,html').animate({
          scrollTop: pos
        }, 1000, 'easeInOutQuint');
        return false;
      }


      /* ----------------------------------------------------------------------------------
      エラーがなければ二重送信禁止
      ---------------------------------------------------------------------------------- */
      $('.submit').find('button').prop('disabled', 'true'); // disabled付与
      setTimeout(function () {
        $('.submit').find('button').prop('disabled', ''); // disabled解除
      }, 1000);
      return true;
    });
  }
}
