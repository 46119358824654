import anime from 'animejs/lib/anime.es.js';
import * as Vivus from 'vivus';
export function eachPageWaveFunc() {
  
  
  let vueFlag, letIndexTop, letPosWaveWrap;
  letIndexTop = $('section#waves').length;
  letPosWaveWrap = $('#control');
  if (letIndexTop) {
    vueFlag = true;
  }
  if (vueFlag) {
    let waveSync1, waveSync2, waveSync3;
    function stroke() {
      setTimeout(function () {
        $('#waveVueAnime1').css('opacity', '1');
        waveSync1 = new Vivus('waveVueAnimeA', {
          type: 'sync',
          duration: 400,
          forceRender: false,
          animTimingFunction: Vivus.EASE_OUT,
          start: 'autostart',
          reverseStack: true,
        });
      }, 500);
    }

    function stroke2() {
      setTimeout(function () {
        $('#waveVueAnime2').css('opacity', '1');
        waveSync2 = new Vivus('waveVueAnimeB', {
          type: 'sync',
          duration: 200,
          forceRender: false,
          animTimingFunction: Vivus.EASE,
          start: 'autostart',
          reverseStack: true,
        });
      }, 1000);
    }

    function stroke3() {
      setTimeout(function () {
        $('#waveVueAnime3').css('opacity', '1');
        waveSync3 = new Vivus('waveVueAnimeC', {
          type: 'sync',
          duration: 200,
          forceRender: false,
          animTimingFunction: Vivus.EASE_OUT,
          start: 'autostart',
          reverseStack: true,
        });
      }, 1500);
    }

    function resetVueFunc() {
      waveSync1.stop().reset();
      waveSync2.stop().reset();
      waveSync3.stop().reset();
    }
    
    //再描画処理実行
    function vueControlFunc() {
      stroke();
      stroke2();
      stroke3();
    }
    
    //初回読み込み時
    function allManagementFunc() {
      stroke();
      stroke2();
      stroke3();
    }
    allManagementFunc();
  }
}