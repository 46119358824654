import anime from 'animejs/lib/anime.es.js';
import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js';
let letHeader, resHeaderHeight;
letHeader = $('#header');

export function styleResetFunc() {
  let resizecheck;
  resizecheck = $('#responsibleCheck').width();
  if (resizecheck != '2') {
    $('#changeJs').removeAttr('style');
    $('.selectBtn').removeClass('open');
    $('#jsEachNav').find('dd').removeAttr('style');
    $('#jsSideNav').find('dd').removeAttr('style');
    $('.imgBox').removeClass('open');
    $('.modalImg').find('.mapImage').removeClass('hide');
    $('.mapBtn').parents('.attention').removeClass('hide');
    $('.modalImg').find('.mapScaleImage').addClass('hide');
    $('.modalImg').find('.backMapBtn').addClass('hide');
    $('#hideContent').removeClass('cancell');
	$('#spNavBtn').removeClass('open');
	$('nav#globalNavigations').removeAttr('style');
	$('body').removeClass('fixedBody').css({
        'top': 0
      });
  }
}
/*メインコンテンツ高さ調整*/
export function heightAdjustmentFunc() {
  resHeaderHeight = letHeader.outerHeight();
  $('main').not('#indexTop').css('padding-top', resHeaderHeight);
  $('#jsSideNav').css('top', resHeaderHeight+10);
}

/*下層ページキービジュアルアニメーション*/
export function eachPageAnimationFunc() {
  anime({
    targets: '#waveSlide',
    opacity: {
      value: [0, 1],
      duration: 2550,
      easing: 'easeInQuint',
    },
    direction: 'normal',
  });
}

export function sticyFunc() {
  let fundPage = $('#serviceFund').width;
  let corpoPage = $('#serviceCorporate').width;

  if (fundPage || corpoPage) {
    // position: stickyがブラウザで使えるかチェックするための関数
    function detectSticky() {
      const div = document.createElement('div');
      div.style.position = 'sticky';
      return div.style.position.indexOf('sticky') !== -1;
    }
    // もしブラウザでposition: stickyが使えなければ、callStickyState関数を呼び出す
    if (!detectSticky()) {
      let jsSideNav = $('#jsSideNav');
      Stickyfill.add(jsSideNav);
    }
  }
}