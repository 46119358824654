///* ----------------------------------------------------------------------------------
//import読み込み
//---------------------------------------------------------------------------------- */
import {
  styleResetFunc,
  heightAdjustmentFunc,
  eachPageAnimationFunc,
  sticyFunc
} from './common/_common';
import {
  headerScrFunc,
  headerOutputFunc,
} from './common/_header';
import {
  footerFunc,
} from './common/_footer';
import {
  hoverFunc,
  telLinkFunc,
  hoverOtherFunc,
  uaHoverFunc,
} from './common/_hoverFunc';

import {
  pageLinkAnimationFunc,
  pageMoveAnkerFunc,
  outSideFunc,
} from './common/_pageLinkAnimation';

import {
  globalNavigationFunc,
  buttonActionFunc,
  careerToggleFunc,
  eachPageSubNavigationFunc,
  sideNavigationFunc,
  zoomInFunc
} from './common/_buttonAction';

import {
  heightLine,
  heightLineSetFunc,
  heightLineFunc
} from './common/_heightLine';

import {
  scrPositionFunc
} from './common/_scrPosSet';

import {
  eachPageWaveFunc
} from './lib/_eachWave';

//* -------------------------------------------------------------------
//各ページ
//------------------------------------------------------------------- */
import {
  idxFunc,
  scrIndexWaveFunc,
  scrIndexListFunc,
} from './pages/_index';

import {
  idxTopicsFunc,
} from './pages/_topic';

import {
  contactFunc,
} from './pages/_contact';
import{
  recruitContactFunc,
} from"./pages/_recruitcontact";

//* -------------------------------------------------------------------
//通常実行
//------------------------------------------------------------------- */
let indexTopLen, setTimer, setResponsibleCheck;
idxTopicsFunc();
//* ----------------------------------------------------------------------------------
//window実行
//---------------------------------------------------------------------------------- */
////// ロード時
$(window).on('load', function () {

  //header関連
  headerOutputFunc();
  globalNavigationFunc();
  //footer関連
  footerFunc();

  //各ページ専用
  idxFunc();
  contactFunc();
  recruitContactFunc();

  //高さ調整
  heightLine();
  heightLineSetFunc();

  //役員・顧問ページトグル
  careerToggleFunc();

  //共通項目
  styleResetFunc();
  heightAdjustmentFunc();
  buttonActionFunc();
  eachPageSubNavigationFunc();
  sideNavigationFunc();
  pageLinkAnimationFunc();
  pageMoveAnkerFunc();
  eachPageAnimationFunc();
  zoomInFunc();
  sticyFunc();
  hoverOtherFunc();
  outSideFunc();
  //スクロールアニメーション
  scrPositionFunc();

  //波の処理
  eachPageWaveFunc();
});

// リサイズ時
$(window).on('load resize', function () {
  //共通項目
  styleResetFunc();
  heightAdjustmentFunc();
});

// スクロール時
$(window).on('load scroll', function () {
  headerScrFunc();
  scrPositionFunc();
  scrIndexWaveFunc();
  scrIndexListFunc();
});
///* ----------------------------------------------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
    navigator.userAgent.indexOf('iPhone') > 0) ||
  (navigator.userAgent.indexOf('iPod') > 0) ||
  (navigator.userAgent.indexOf('iPad') > 0) ||
  (navigator.userAgent.indexOf('Android') > 0) ||
  (navigator.userAgent.indexOf('BlackBerry') > 0) ||
  (navigator.userAgent.indexOf('PlayBook') > 0) ||
  (navigator.userAgent.indexOf('Kindle') > 0) ||
  (navigator.userAgent.indexOf('Silk') > 0) ||
  (navigator.userAgent.indexOf('Windows Phone') > 0) ||
  (navigator.userAgent.indexOf('Nokia') > 0)
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
  uaHoverFunc();
} else {
  // PC時の処理
  uaFlag = 3;
  hoverFunc();
  telLinkFunc();
}
/* ----------------------------------------------------------------------------------
IEだけの時
---------------------------------------------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}